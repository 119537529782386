import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import MarkdownParser from '../MarkdownParser';
import Container from '../Container';
import { H1, H2, H4 } from '../Heading';
import Image from '../Image';

const SectionTitleBlock = styled.div`
  margin: 6rem 0 2em;
  padding-bottom: ${props => (props.isLastItem ? '2em' : 0)};
`;

const DividerImage = styled(Image)`
  width: 80%;
  margin: 0 auto 1em;
`;

const SectionTitle = ({ fields, ...rest }) => {
  const { image, sectionTitle, eyebrow, headingLevel } = fields;
  const imageSrc =
    image && image.fields && image.fields.file && image.fields.file.url;
  const imageAlt =
    image && image.fields && (image.fields.description || image.fields.title);

  let Heading = H1;
  switch (headingLevel) {
    case 'H1':
      Heading = H1;
      break;
    case 'H2':
      Heading = H2;
      break;
    default:
      Heading = H1;
      break;
  }

  return (
    <SectionTitleBlock isLastItem={rest.index + 1 === rest.numberOfBlocks}>
      {imageSrc && <DividerImage src={imageSrc} alt={imageAlt} />}
      <Container>
        {eyebrow && <H4>{eyebrow}</H4>}
        {sectionTitle && (
          <Heading>
            <MarkdownParser>{sectionTitle}</MarkdownParser>
          </Heading>
        )}
      </Container>
    </SectionTitleBlock>
  );
};

SectionTitle.propTypes = {
  fields: PropTypes.shape({
    sectionTitle: PropTypes.string,
    image: PropTypes.shape({
      sys: PropTypes.shape({
        id: PropTypes.string,
      }),
      fields: PropTypes.shape({
        title: PropTypes.string,
        eyebrow: PropTypes.string,
        headingLevel: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
};

SectionTitle.defaultProps = {
  fields: {
    eyebrow: null,
    sectionTitle: null,
    image: null,
    headingLevel: 'H1',
  },
};

export default SectionTitle;
