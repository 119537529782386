import React from 'react';
import styled from 'styled-components/macro';
import Container from '../Container';
import Counter from '../Counter';
import MarkdownParser from '../MarkdownParser';
import { Paragraph } from '../Text';
import { ChartTitle } from '../ChartElements';
import Scribble from '../Scribble';

const Block = styled.div`
  margin: 3em auto;
  padding-bottom: ${props => (props.isLastItem ? '2em' : 0)};
  /* max-width: 30rem; */

  @media ${props => props.theme.breakpoints.small} {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`;

const Icon = styled.img`
  display: block;
  max-width: 5em;
  height: auto;
  margin: 0 0 2.5em;
`;

const Content = styled(Paragraph)`
  font-size: 1.6rem;
  line-height: 1.3;
  margin: 0;

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 1.4rem;
  }
`;

const Layout = styled.div`
  ${props =>
    props.horizontal &&
    `
    display: flex;
    align-items: center;
    justify-content: space-between;
    `}
`;

const CounterContainer = styled.div`
  ${props => props.horizontal && `width: 15rem;`}
  font-weight: 700;
  font-size: 3.5em;
  text-align: center;

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 2em;
  }
`;

const ContentContainer = styled.div`
  max-width: ${props => (props.horizontal ? 'calc(100% - 15rem);' : '25rem')};
  margin: 0 auto;
  font-weight: 400;
  font-size: 0.9em;
  line-height: 1.4;
  text-align: center;

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    font-size: 0.8em;
    line-height: 1.2;
  }
`;

const StyledScribble = styled(Scribble)`
  margin: 1em auto 2em;
`;

const CounterBlock = ({ fields, ...props }) => {
  const {
    adminTitle,
    displayTheTitle,
    number,
    prefix,
    suffix,
    // icon,
    content,
    horizontalLayout,
  } = fields;
  const iconSrc =
    fields.icon &&
    fields.icon.fields &&
    fields.icon.fields.file &&
    fields.icon.fields.file.url;
  const iconAlt =
    fields.icon &&
    fields.icon.fields &&
    (fields.icon.fields.description || fields.icon.fields.title);

  return (
    <Block isLastItem={props.index + 1 === props.numberOfBlocks}>
      <Container>
        <Layout horizontal={horizontalLayout}>
          {adminTitle && displayTheTitle && (
            <ChartTitle>{adminTitle}</ChartTitle>
          )}
          {iconSrc && <Icon src={iconSrc} alt={iconAlt}></Icon>}
          <CounterContainer horizontal={horizontalLayout}>
            <Counter
              end={number.toString()}
              prefix={prefix}
              suffix={suffix}
            ></Counter>
          </CounterContainer>
          <ContentContainer horizontal={horizontalLayout}>
            {content && (
              <MarkdownParser
                overrides={{
                  p: { component: Content },
                  // span: { component: Content },
                }}
              >
                {content}
              </MarkdownParser>
            )}
          </ContentContainer>
        </Layout>
        <StyledScribble type={4} inline={false} width="15rem" />
      </Container>
    </Block>
  );
};

export default CounterBlock;
