import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useTrail, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';

const FlutteredList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const FlutteredItem = animated(styled.li`
  background: #eee;
  margin-bottom: 1em;
  padding: 0.5em 1em;
`);

/**
 * Animate an ordered or unordered list using React Spring.
 * See https://codesandbox.io/embed/zn2q57vn13 as a reference on which this was
 * based.
 */
const Flutter = props => {
  const [shouldAnimate, setAnimate] = useState(false);

  const children = props.children[0].props.children.map(
    x => x.props.children[0]
  );
  // const listType = props.children[0].type;

  const trail = useTrail(children.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: shouldAnimate ? 1 : 0,
    x: shouldAnimate ? 0 : 20,
    from: { opacity: 0, x: props.direction === 'down' ? -20 : 20 },
  });

  return (
    <>
      {/** Don't wrap the Flutter in the waypoint bc there's some ref issues */}
      <Waypoint
        topOffset="30%"
        bottomOffset="30%"
        onEnter={() => (!shouldAnimate ? setAnimate(true) : false)}
      />
      {/**
       * TODO: Need to find a way to use the listType to set the styled component as
       * either a ul or ol. It is explicity set as a ul for now.
       */}
      <FlutteredList ref={props.innerRef}>
        {trail.map(({ x, ...rest }, index) => (
          <FlutteredItem
            key={children[index]}
            style={{
              ...rest,
              transform: x.interpolate(x => `translate3d(0,${x}px,0)`),
            }}
          >
            {children[index]}
          </FlutteredItem>
        ))}
      </FlutteredList>
    </>
  );
};

Flutter.propTypes = {
  children: PropTypes.node.isRequired,
  direction: PropTypes.string,
};

Flutter.defaultProps = {
  direction: 'down',
};

export default Flutter;
