import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import MarkdownParser from '../MarkdownParser';
import Container from '../Container';

const DefaultBlock = styled.div`
  margin: 2em 0;
  padding-bottom: ${props => (props.isLastItem ? '2em' : 0)};
`;

/** The most basic type of block we can render */
const Default = ({ fields, isNested, fullWidth, ...rest }) => {
  const { body } = fields;
  return (
    <DefaultBlock isLastItem={rest.index + 1 === rest.numberOfBlocks}>
      <Container>
        <MarkdownParser>{body}</MarkdownParser>
      </Container>
    </DefaultBlock>
  );
};

Default.propTypes = {
  fields: PropTypes.shape({
    /** Body is the only required child of fields */
    body: PropTypes.string.isRequired,
  }).isRequired,
  fullWidth: PropTypes.bool,
};

Default.defaultProps = {
  isNested: false,
  fullWidth: false,
};

export default Default;
