import Hero from '../components/blocks/Hero';
import Default from '../components/blocks/Default';
import Callout from '../components/blocks/Callout';
import Counter from '../components/blocks/Counter';
import BarChart from '../components/blocks/BarChart';
import Media from '../components/blocks/Media';
import SectionTitle from '../components/blocks/SectionTitle';
import Grid from '../components/blocks/Grid';
import CollapsibleContent from '../components/CollapsibleContent/CollapsibleContent';
import Html from '../components/blocks/HTML';

/**
 * Define default blocks available for sections, matching the content type's
 * machine name and the actual component name. If you have a content type
 * defined in the content (remote or local), it must have a block associated
 * with it or it won't display.
 *
 * For example, if you add a custom callout content type, you'll need a Callout
 * component, and it needs to be defined here as { callout: Callout }
 */
export default {
  hero: Hero,
  content: Default,
  callout: Callout,
  collapsibleContent: CollapsibleContent,
  counter: Counter,
  barChart: BarChart,
  media: Media,
  sectionTitle: SectionTitle,
  grid: Grid,
  html: Html,
};
