import styled from 'styled-components/macro';
import { H4 } from './Heading';

export const LegendContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1em auto;
`;

export const LegendItem = styled.span`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 50%;
  font-size: 1.5rem;
  /** Gives it the same scaling as the SVG */
  /* font-size: 1.6vw; */
  font-family: ${props => props.theme.fonts.sansSerif};
  font-weight: 300;
  color: ${props => props.theme.text || props.theme.colors.assfault};
  margin: 0.5em 0.5em;
  line-height: 1.3;
  width: ${props => (props.width ? props.width + '%' : 'auto')};

  &::before {
    content: '';
    flex: 0 0 auto;
    display: inline-block;
    width: 1em;
    height: 1em;
    margin: 0.1em 0.5em 0 0;
    background: ${props => {
      /** Iterate over each group or the single bars as a whole, with safety checks */
      if (props.highestGroupCount === 1 && props.theme.singleBarChart) {
        /** Allow the chart colors to cycle through the colors array */
        return props.theme.singleBarChart[
          props.index % props.theme.singleBarChart.length
        ];
      }
      return props.theme.charts
        ? /** Allow the chart colors to cycle through the colors array */
          props.theme.charts[props.index % props.theme.charts.length]
        : props.theme.colors.assfault;
    }};
    border-radius: 100%;
  }
`;

export const ChartTitle = styled(H4)`
  text-align: ${props => (props.fullWidth ? 'left' : 'center')};
`;

export const ChartIntro = styled.div`
  text-align: ${props => (props.fullWidth ? 'left' : 'center')};
  margin-bottom: 1em;
`;
