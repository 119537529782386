import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { linkStyles } from './Links';

const FormContainer = styled.div`
  font-size: 2rem;

  .hs-form-field {
    margin: 1em 0;
  }

  label,
  legend {
    display: block;
    color: ${props => props.theme.colors.asphalt};
    font-family: ${props => props.theme.fonts.sofiapro};
    font-size: 0.8em;
    font-weight: 300;
    margin: 0 0 0.2em;
  }

  legend {
    color: ${props => props.theme.colors.seal};
  }

  a {
    ${linkStyles}
  }

  input,
  select,
  textarea {
    font-size: 0.8em;
    background: ${props => props.theme.colors.cloud};
    color: ${props => props.theme.colors.asphalt};
    display: block;
    padding: 0.8em !important;
    width: 100%;
    border: none;
    margin: 0;
  }

  select {
    padding: 0.6em !important;
  }

  textarea {
    resize: vertical;
  }

  input[type='submit'] {
    display: block;
    margin: 0 auto;
    width: 200px;
    cursor: pointer;
    // overflow: hidden;
    font-family: ${props => props.theme.fonts.sofiapro};
    border: 0;
    position: relative;
    appearance: none;
    border: 0;
    text-decoration: none;
    font-size: 0.9em;
    line-height: 0.9em;
    color: ${props => props.theme.colors.white};
    font-weight: 700;
    border-radius: 2rem;
    padding: 0.5em 1em;
    transition: all 0.4s ease-in-out;
    background-image: linear-gradient(
      to right,
      ${props => props.theme.colors.blue},
      ${props => props.theme.colors.lightpurple},
      ${props => props.theme.colors.purple}
    );
    background-size: 200% 100%;
    // border: 3px solid ${props => props.theme.colors.blue};

    // &:hover {
    //   background: none;
    // }

    &:hover,
    &:active {
      color: ${props => props.theme.colors.white};

    }

    @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
      max-width: 100%;
    }
  }

  .inputs-list {
    padding: 0;
    margin: 0;
  }

  li.hs-form-radio {
    list-style: none;

    input {
      display: inline-block;
      width: auto;
      margin-right: 1em;
    }
  }

  .submitted-message,
  .legal-consent-container {
    color: ${props => props.theme.colors.white};

    a {
      color: ${props => props.theme.colors.white};
    }
  }

  .legal-consent-container {
    font-size: 0.8em;
  }

  .hs-error-msgs {
    margin: 0.5em 0 0;
    padding: 0;
    list-style: none;
  }

  .hs-form-required {
    color: ${props => props.theme.colors.purple};
  }

  .hs-error-msg {
    color: ${props => props.theme.colors.purple};
    font-size: 0.8em;
    line-height: 1.2;
  }
`;

const HubspotForm = ({ portalId, formId, id, ...props }) => {
  const didLoad = useRef(false);
  /**
   * Custom-load the external form script so we can track when it loads or fails
   * and make any customizations needed when it does
   */
  useEffect(() => {
    if (window.hbspt && portalId && formId && id) {
      if (!didLoad.current) {
        /** Quick fix to prevent the form loading many times */
        didLoad.current = true;

        window.hbspt.forms.create({
          portalId: portalId,
          formId: formId,
          target: `#${id}`,
          css: '',
          ...props,
        });
      }
    }
  }, [formId, id, portalId, props]);

  return <FormContainer id={id} className="hubspot-container"></FormContainer>;
};

export default HubspotForm;

// import React, { useEffect } from 'react';
// import styled from 'styled-components/macro';
// import HubspotForm from '../HubspotForm';

// const Block = styled.div`
//   background: ${props => props.theme.colors.smokem};
//   border-radius: 0.3em;
//   padding: 1.5em;
//   max-width: 40rem;
//   /* margin: 0 auto; */
// `;

// const HubspotFormBlock = ({ id, ...props }) => {
//   return (
//     <Block>
//       <HubspotForm {...props} id={id || `hubspot-form-${props.formId}`} />
//     </Block>
//   );
// };

// export default HubspotFormBlock;
