import { createGlobalStyle } from 'styled-components/macro';
import { normalize } from 'polished';
import '../config/fontFace.css';

/**
 * This is a one-time-use method to add global styles.
 *
 * Use for things like body and html styles, and anything that else can't fit as
 * a react component
 */
export default createGlobalStyle`
  ${normalize()}

  * {
    box-sizing: border-box;
  }

  html {
    /** Allows us to easily translate px to rem, e.g. 1.8rem = 18px */
    font-size: 62.5%;
  }

  html,
  body {
    overflow-x: hidden;
    max-width: 100%;
  }

  body {
    font-family: ${props => props.theme.fonts.sofiapro};
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.6;
  }
`;
