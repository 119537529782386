import React from 'react';
import Image from './Image';
import styled from 'styled-components/macro';
import Logo from '../assets/logo.png';

/**
 * The logo, which usually just goes in the header. This could easily go inside
 * the Header component, but this keeps it a little easier to read
 */

// export default styled.img``;

/** SVGs are already compiled as react components, so just do this */

const StyledLogo = styled(Image)`
  display: block;
  width: 10em;
  height: auto;
`;

/** If we wanted to export an actual image we could do this: */
export default () => <StyledLogo src={Logo} alt="Logo" />;
