/** Define common colors, fonts, and other branding here */
const colors = {
  white: '#fff',
  gray: '#ddd',
  black: '#000',
  cloud: '#eff1f4',
  seal: '#99a6b7',
  asphalt: '#434749',

  /** For gradients */
  blue: '#20c4f4',
  lightpurple: '#5963d6',
  purple: '#8f06ba',
};

/** Light theme for each section */
const white = {
  textColor: colors.black,
  secondaryTextColor: colors.seal,
  background: colors.white,
  elementBackground: colors.cloud,
  link: colors.black,
  linkHover: colors.black,
  tweet: colors.black,
  button: colors.black,
  buttonText: colors.white,
};

/** Dark theme for each section */
const black = {
  textColor: colors.white,
  secondaryTextColor: colors.seal,
  background: colors.black,
  elementBackground: colors.asphalt,
  link: colors.white,
  linkHover: colors.white,
  tweet: colors.white,
  button: colors.white,
  buttonText: colors.black,
};

/** Medium theme for each section */
const gray = {
  textColor: colors.black,
  secondaryTextColor: colors.seal,
  background: colors.cloud,
  elementBackground: colors.white,
  link: colors.black,
  linkHover: colors.black,
  tweet: colors.black,
  button: colors.black,
  buttonText: colors.white,
};

const fonts = {
  serif: 'serif',
  sansSerif: 'sans-serif',
  sofiapro: '"Sofia Pro", Helvetica, Arial, sans-serif',
};

const breakpoints = {
  small: '600px',
  medium: '850px',
  large: '1300px',
  xlarge: '1600px',
};

const animations = {
  duration: '0.1s',
  easing: 'ease-out',
};

const shadow =
  '0 0.8px 1px -16px rgba(0, 0, 0, 0.05), 0 2px 2.6px -16px rgba(0, 0, 0, 0.071), 0 4.1px 5.3px -16px rgba(0, 0, 0, 0.089), 0 8.4px 11px -16px rgba(0, 0, 0, 0.11), 0 23px 30px -16px rgba(0, 0, 0, 0.16)';

const borders = {
  radius: '5px',
};

const theme = {
  colors,
  fonts,
  breakpoints,
  animations,
  shadow,
  borders,
  white,
  black,
  gray,
};

export default theme;
