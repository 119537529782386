import React from 'react';
import { Waypoint } from 'react-waypoint';

/** Wrap react-waypoint for more consistent use throughout */
export default props => {
  const { onLeave, onPositionChange } = props;

  return (
    <Waypoint
      {...props}
      onPositionChange={position => {
        /**
         * In the case where the page is already scrolled down at the point when
         * the waypoint initializes, handle a "no previous, current above/below"
         * by calling onLeave with the position.
         *
         * https://github.com/civiccc/react-waypoint/issues/253#issuecomment-410819555
         */
        if (
          typeof position.previousPosition === 'undefined' &&
          position.currentPosition !== Waypoint.inside &&
          onLeave
        ) {
          return onLeave(position);
        }
        onPositionChange && onPositionChange(position);
      }}
      onLeave={onLeave}
    />
  );
};
