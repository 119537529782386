import styled from 'styled-components/macro';

/** Set up default heading elements */

export const H1 = styled.h1`
  margin: 0 0 1em;
  font-size: 2.1em;
  line-height: 1.1;
  font-weight: 800;
  position: relative;
  z-index: 1;
`;

export const H2 = styled.h2`
  margin: 0 0 0.3em;
  font-size: 1.8em;
  line-height: 1.1;
  font-weight: 700;
`;

export const H3 = styled.h3`
  margin: 0 0 0.1em;
  font-size: 1.2em;
  line-height: 1.1;
  font-weight: 700;
`;

export const H4 = styled.h4`
  margin: 0 0 0.8em;
  font-size: 0.9em;
  line-height: 1.1;
  font-weight: normal;
  color: ${props => props.theme.secondaryTextColor};
  text-transform: uppercase;
`;
