/** A place to put miscellaneous config options */
export default {
  shouldUseRemoteContent: true,
  contentful: {
    space: 'nfoll311sgzw',
    accessToken: 'tQeMlHnPql-sDlN30vxZAaoCMt0Fqr3oNFlyM2GG7lY',
  },
  isPaged: false,
  isHeaderUnderFirstBlock: false,
  /** 'dot' or 'full' */
  nav: 'dot',
  twitterHandle: '',
};
