import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from '../assets/arrow.svg';

const StyledLinkBare = styled.a`
  text-decoration: none;
  color: inherit;
`;

export const linkStyles = css`
  transition-property: color, border;
  transition-duration: ${props => props.theme.animations.duration};
  transition-timing-function: ${props => props.theme.animations.easing};
  color: ${props => props.theme.textColor};
  text-decoration: none;
  border-bottom: 2px solid ${props => props.theme.textColor};

  &:hover {
    color: ${props => props.theme.textColor};
    border-bottom-color: inherit;
    border-image-source: linear-gradient(
      45deg,
      ${props => props.theme.colors.blue},
      ${props => props.theme.colors.purple}
    );
    border-image-slice: 1;
    background-image: linear-gradient(
      45deg,
      ${props => props.theme.colors.blue},
      ${props => props.theme.colors.purple}
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-position: 300% 100%;
    transition: background-position 0.4s ease-in-out;
  }
`;

// const animateBackground = keyframes`
//   0% {
//     background-position: 100% 100%;
//   }
//   100% {
//     background-position: 300% 100%;
//   }
// `;

const StyledLink = styled.a`
  // display: inline-block;
  text-decoration: none;
  border-bottom: 2px solid ${props => props.theme.textColor};
  color: ${props => props.theme.textColor};
  font-weight: 400;
  transition: all 0.4s ease-in-out;

  &:hover {
    // border-image-source: linear-gradient(
    //   45deg,
    //   ${props => props.theme.colors.blue},
    //   ${props => props.theme.colors.purple}
    // );
    // border-image-slice: 1;
    // background-color: ${props => props.theme.colors.blue};
    background-image: linear-gradient(
      45deg,
      ${props => props.theme.colors.blue},
      ${props => props.theme.colors.purple}
    );
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    background-position: 300% 100%;
    transition: all 0.4s ease-in-out;
  }
`;

const StyledButton = styled.a`
  position: relative;
  appearance: none;
  border: 0;
  text-decoration: none;
  font-size: 0.9em;
  line-height: 0.9em;
  color: ${props => props.theme.textColor};
  font-weight: 700;
  background-image: linear-gradient(
    to right,
    ${props => props.theme.colors.blue},
    ${props => props.theme.colors.lightpurple},
    ${props => props.theme.colors.purple}
  );
  background-size: 200% 100%;
  border-radius: 2rem;
  padding: 0.5em 1em;
  transition: all 0.4s ease-in-out;

  &::before {
    content: '';
    position: absolute;
    background: ${props => props.theme.background};
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform: scale(0.925, 0.8);
    z-index: 0;
    border-radius: 2rem;
  }

  &:hover {
    background-size: 100% 100%;

    svg {
      transform: translateX(2px);
    }
  }

  svg {
    transition: transform 0.4s ease-in-out;
  }

  span {
    position: relative;
    z-index: 2;
  }
`;

const StyledArrow = styled(Arrow)`
  // path {
  //   fill: linear-gradient(
  //   45deg,
  //   ${props => props.theme.colors.blue},
  //   ${props => props.theme.colors.purple}
  //   );
  // }
`;

const useLink = props => {
  /**
   * Determine if a link is external by constructing a URL object from the prop,
   * then comparing the hostname of the URL with the window's hostname. If the
   * `href` prop is a relative link like '/test', the window.location will be
   * used as a base. If it's an absolute URL like 'https://google.com', the base
   * will be ignored.
   *
   * Also add in a special check for the public/assets folder
   */
  const isExternal =
    new URL(props.href, window.location).hostname !==
      window.location.hostname || props.href.substring(0, 8) === '/assets/';

  const attrs = isExternal
    ? /** Add extra attributes for external links */
      {
        href: props.href,
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : /** Internal Link components need the "to" prop instead of "href" */
      {
        to: props.href,
      };

  return [attrs, isExternal];
};

/** Links that look like buttons */
export const Button = props => {
  const [attrs, isExternal] = useLink(props);
  return (
    <StyledButton
      {...props}
      {...attrs}
      as={isExternal ? undefined : Link}
      className={props.className}
    >
      <span>
        {props.children} <StyledArrow />
      </span>
    </StyledButton>
  );
};

/** An unstyled link, good for wrapping images */
export const LinkBare = props => {
  const [attrs, isExternal] = useLink(props);
  return (
    <StyledLinkBare
      {...props}
      {...attrs}
      /** If the link is internal, use a react-router Link */
      as={isExternal ? undefined : Link}
      /** Add the className so any other components can override the styling */
      className={props.className}
    >
      {props.children}
    </StyledLinkBare>
  );
};

/** Standard text links */
export default props => {
  const [attrs, isExternal] = useLink(props);
  return (
    <StyledLink
      {...props}
      {...attrs}
      /** If the link is internal, use a react-router Link */
      as={isExternal ? undefined : Link}
      /** Add the className so any other components can override the styling */
      className={props.className}
    />
  );
};
