import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import Waypoint from './Waypoint';
import { ReactComponent as Squiggle1 } from '../assets/squiggle-1.svg';
import { ReactComponent as Squiggle2 } from '../assets/squiggle-2.svg';
import { ReactComponent as Squiggle3 } from '../assets/squiggle-3.svg';
import { ReactComponent as Squiggle4 } from '../assets/squiggle-4.svg';

const draw = keyframes`
  0% {
    stroke-opacity: 0;
    stroke-dashoffset: 497;
  }
  50% {
    stroke-opacity: 1;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-opacity: 1;
    stroke-dashoffset: 0;
  }
`;

const ScribbleContainer = styled.div`
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  margin-top: ${props => (props.inline ? '0' : '2rem')};
  position: relative;
`;

const StyledScribble = styled.div`
  margin: 0 auto;
  width: ${props => props.width};
  position: ${props => (props.inline ? 'absolute' : 'relative')};
  z-index: ${props => (props.inline ? '-1' : '0')};
  max-width: 100%;

  svg {
    // position: absolute;
    // top: 100%;
    // left: 0;
    display: block;
    width: 100%;
    height: auto;
  }

  .svg-mask {
    fill: ${props => props.theme.background};
  }

  .animate-me {
    fill: ${props => props.theme.background};
    stroke-dasharray: 497;
    stroke-dashoffset: 497;
    stroke-opacity: 1;
    ${props =>
      props.shouldAnimate &&
      css`
        animation: ${draw} 3s ease-in-out 4ms both;
      `};
  }
`;

const Scribble = props => {
  const [shouldAnimate, setAnimate] = useState(false);
  return (
    <Waypoint
      topOffset="0"
      bottomOffset="0"
      onEnter={() => setAnimate(true)}
      onLeave={() => setAnimate(false)}
    >
      <ScribbleContainer inline={props.inline}>
        {props.children}
        <StyledScribble
          inline={props.inline}
          width={props.width}
          shouldAnimate={shouldAnimate}
        >
          {props.type === 1 && <Squiggle1 alt="" />}
          {props.type === 2 && <Squiggle2 alt="" />}
          {props.type === 3 && <Squiggle3 alt="" />}
          {props.type === 4 && <Squiggle4 alt="" />}
        </StyledScribble>
      </ScribbleContainer>
    </Waypoint>
  );
};

Scribble.defaultProps = {
  type: 1,
  inline: true,
  width: '100%',
};

export default Scribble;
