import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import MarkdownParser from '../MarkdownParser';
import Container from '../Container';
import Scribble from '../Scribble';

const CalloutBlock = styled.div`
  margin: 2em;
  text-align: center;
  font-size: 1.2em;
  font-weight: 700;

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    margin: 2em 0;
  }
`;

const StyledContainer = styled(Container)`
  padding: 0.5em;
`;

/** The most basic type of block we can render */
const Callout = ({ fields, ...rest }) => {
  const { content } = fields;
  return (
    <CalloutBlock>
      <StyledContainer>
        <MarkdownParser>{content}</MarkdownParser>
        <Scribble type={2} inline={false} width="25%" />
      </StyledContainer>
    </CalloutBlock>
  );
};

Callout.propTypes = {
  fields: PropTypes.shape({
    /** Body is the only required child of fields */
    content: PropTypes.string.isRequired,
  }).isRequired,
};

export default Callout;
