import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { Waypoint } from 'react-waypoint';
import { createCookie } from '../../utilities';
import { setGateRelease } from '../../logic/actions';
// import { disableGate } from '../../logic/actions';
// import GateForm from './GateForm';
import { Paragraph } from '../Text';
import HubspotForm from '../HubspotForm';

/**
 * You might need to change this based on how much space to put between it and
 * the top of its parent Section. If the lightpaper is paged, then the waypoint
 * will fire on a gated Section before this takes effect
 */
const gateOffset = '30em';

/**
 * Sets the position and dimensions of the gate, but doesn't actually center it
 * vertically with the window. See the comment over WaypointStyled for more info
 */
const GateWrapper = styled.div`
  position: ${props => (props.isStuck ? 'fixed' : 'absolute')};
  z-index: 100000;
  top: ${props => (props.isStuck ? '50%' : gateOffset)};
  left: 50%;
  transform: translateX(-50%);
`;

/**
 * Sets the actual styles of the gate, and positions it to be center of the
 * screen. Use this for theming
 */
const GateInner = styled.div`
  padding: 2em;
  background: white;
  box-shadow: ${props => props.theme.shadow};
  border-radius: ${props => props.theme.borders.radius};
  transform: translateY(-50%);
  width: 80vw;
  max-width: 30em;
  height: 100%;
  max-height: 90vh;
  overflow: auto;

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    padding: 1em;
    width: 90vw;
  }
`;

const StyledP = styled(Paragraph)`
  color: ${props => props.theme.colors.black};
`;

/**
 * Triggers the waypoint by matching the position of the top of the Gate. It has
 * to match the top instead of the middle (like DotNavigation) because we don't
 * know the Gate's height, the Gate doesn't have a predictable percentage offset
 * based on its parent Section, and rendering it inside the Gate would cause it
 * to become position: fixed, too, which would break it
 */
const WaypointStyled = styled.span`
  position: absolute;
  z-index: -1;
  top: ${gateOffset};
`;

/**
 * We currently need to map innerRef to a real ref to make react-waypoint work
 * with styled-components v4
 */
export const WaypointTrigger = ({ innerRef, ...props }) => (
  <WaypointStyled ref={innerRef} {...props} />
);

const Gate = props => {
  const [isStuck, setStuck] = useState(false);

  /** When the form is submitted, disable the gate "permanently" */
  const handleGateDisable = () => {
    createCookie('gate-released', 'true', 90);
    /** Give time for the animations to finish */
    setTimeout(() => props.setGateRelease(true), 600);
  };

  return (
    <>
      <Waypoint
        topOffset="50%"
        onPositionChange={event => setStuck(event.currentPosition === 'above')}
      >
        <WaypointTrigger />
      </Waypoint>
      <GateWrapper isStuck={isStuck} isGateReleased={props.isGateReleased}>
        <GateInner>
          {/** Replace this with whatever third-party form you're using */}
          <StyledP>
            To continue reading, please provide your contact details.
          </StyledP>
          {/** Replace this with whatever third-party form you're using */}
          {/* <GateForm handleGateDisable={handleGateDisable} /> */}
          {/* {isFormLoading && <SpinnerStyled />} */}
          {/* {isFormLoadingInvalidated && (
              <Paragraph>
                <strong>
                  Could not load the form. Please try refreshing the page.
                </strong>
              </Paragraph>
            )} */}
          <HubspotForm
            id="hubspot-gate"
            formId="5c8c7a4d-82e1-47ee-971b-780e5f89c6cb"
            portalId="7014026"
            redirectUrl={window.location}
            onFormSubmitted={$form => {
              handleGateDisable();
              setTimeout(() => props.setGateRelease(true), 2000);
              return false;
            }}
          ></HubspotForm>
        </GateInner>
      </GateWrapper>
    </>
  );
};

const mapStateToProps = state => ({
  isGateReleased: state.isGateReleased,
});

const mapDispatchToProps = { setGateRelease };

export default connect(mapStateToProps, mapDispatchToProps)(Gate);
