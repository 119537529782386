import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { ReactComponent as Line } from '../assets/squiggle-3.svg';

/** Set up default text block elements */
export const Paragraph = styled.p`
  margin: 0 0 1em;
  color: ${props => props.theme.primary};
`;

export const Span = styled.span``;

const StyledBlockQuote = styled.blockquote`
  font-style: italic;
  font-weight: 300;
  margin: 2.5em;
`;

const draw = keyframes`
  0% {
    stroke-opacity: 0;
    stroke-dashoffset: 497;
  }
  50% {
    stroke-opacity: 1;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-opacity: 1;
    stroke-dashoffset: 0;
  }
`;

const StyledLine = styled(Line)`
  display: block;
  margin: 1em 0;

  .svg-mask {
    fill: ${props => props.theme.background};
  }

  .animate-me {
    stroke-dasharray: 497;
    stroke-dashoffset: 497;
    stroke-opacity: 1;
    animation: ${draw} 3s ease-in-out 4ms infinite;
  }
`;

export const Blockquote = props => {
  return (
    <StyledBlockQuote>
      {props.children}
      <StyledLine />
    </StyledBlockQuote>
  );
};
