import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components/macro';
import { Waypoint } from 'react-waypoint';

const activeClassName = 'nav-active';

const NavContainer = styled.div`
  /** Prevent the Container from sitting on top of the content */
  pointer-events: none;
  user-select: none;
  position: absolute;
  /** This needs to be a higher z-index than the header */
  z-index: 2000;
  right: 0;

  ${({ isStuck }) =>
    isStuck &&
    css`
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      transform: translateX(-50%);
    `};
`;

/** The nav itself */
const Nav = styled.nav`
  position: ${props => (props.isStuck ? 'fixed' : 'absolute')};
  z-index: 900;
  padding: 1em;
  font-size: 2rem;
  /**
   * When not stuck, the nav will first show to the user at half the window's
   * height past the start of the second section
   */
  top: ${props => (props.isStuck ? '50%' : 'calc(100% + 50vh)')};
  right: 0%;
  transform: translateY(-50%);
  /** Override the container's event removal */
  pointer-events: auto;
  user-select: auto;

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    right: -1rem;
  }
`;

/** The react-router link for each nav item */
const NavItem = styled(NavLink)`
  display: block;
  position: relative;
  line-height: 1;
  margin-bottom: 0.5em;
`;

const bloopDeBloop = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  90% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.25);
  }
`;

/** The actual dot for a nav item */
const Dot = styled.span`
  position: relative;
  border-radius: 100%;
  width: 1em;
  height: 1em;
  display: block;
  background-color: ${props => props.theme.elementBackground};
  transition: background 0.6s ease-in, transform 0.3s ease-in;

  &::after {
    content: '';
    position: absolute;
    background: ${props => props.theme.background};
    border-radius: 50%;
    transition: background 0.6s ease-in;
    width: 0.7em;
    height: 0.7em;
    top: 0.15em;
    left: 0.15em;
  }

  ${NavItem}:hover &,
  .${activeClassName} & {
    background-image: linear-gradient(
      45deg,
      ${props => props.theme.colors.blue},
      ${props => props.theme.colors.purple}
    );
    animation: ${bloopDeBloop} 1s ease-in-out 4ms both;

    &::after {
      // transform: scale(1);
      // animation: ${bloopDeBloop} 1s ease-in-out 4ms both;
    }
  }
`;

/**
 * Wrap the styled title to give it a max-width while still allowing the visible
 * Title to be smaller, which doesn't work when applying the width directly to
 * the Title. That happens bc its parent container's width (Nav) is smaller than
 * the Title
 */
const TitleWrapper = styled.div`
  position: absolute;
  z-index: -1;
  right: calc(100% + 0.5em);
  top: 50%;
  transform: translateY(-50%);
  width: 20em;
  text-align: right;
  pointer-events: none;
  /** When hovered over, this gives some extra space around the title */
  padding: 2em 0;
  font-size: 1.4rem;

  ${NavItem}:hover & {
    pointer-events: auto;
  }
`;

/** The text for the nav item */
const Title = styled.span`
  display: inline-block;
  position: relative;
  opacity: 0;
  visibility: hidden;
  max-width: 100%;
  text-align: left;
  transition: opacity 0.3s ${props => props.theme.animations.easing},
    transform 0.15s ${props => props.theme.animations.easing},
    visibility 0s ${props => props.theme.animations.easing};
  background: ${props => props.theme.elementBackground};
  padding: 1em;
  font-size: 1em;
  color: ${props => props.theme.textColor};
  transform: translateX(2em);
  font-weight: 700;
  box-shadow: ${props => props.theme.shadow};
  border-radius: ${props => props.theme.borders.radius};

  ${NavItem}:hover & {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }
`;

/**
 * Center the waypoint vertically in the dot navigation, so it triggeres when
 * the middle of the nav hits the middle of the window
 */
const WaypointStyled = styled.span`
  position: absolute;
  z-index: -1;
  top: calc(100% + 50vh);
  transform: translateY(-50%);
  font-size: 0;
`;

/**
 * Take each menu item and output it as a "dot" style navigation, which becomes
 * sticky in the middle of the window vertically and hides its menu title until
 * the user interacts with each dot
 */
const DotNavigation = ({ menuItems, location }) => {
  const [isStuck, setStuck] = useState(false);

  return (
    <>
      <Waypoint
        topOffset="50%"
        onPositionChange={event => setStuck(event.currentPosition === 'above')}
      >
        <WaypointStyled />
      </Waypoint>
      <NavContainer size="wide" isStuck={isStuck}>
        <Nav isStuck={isStuck}>
          {menuItems.map((item, index) => (
            <NavItem
              exact
              /**
               * There's something wonky with the scrolling part of the route
               * changing, so this helps ensure that the active item is always
               * the right path
               */
              isActive={() => location.pathname === item.path}
              index={index}
              key={`menu-${item.id}`}
              to={item.path}
              activeClassName={activeClassName}
            >
              <TitleWrapper index={index}>
                <Title index={index}>{item.title}</Title>
              </TitleWrapper>
              <Dot
                index={index}
                isActive={() => location.pathname === item.path}
              />
            </NavItem>
          ))}
        </Nav>
      </NavContainer>
    </>
  );
};

export default DotNavigation;
