import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Icon from '../Icon';
import { useSpring, animated } from 'react-spring';
import useMeasure from './useMeasure';
import { H3 } from '../Heading';
import { ReactComponent as ArrowIcon } from './chevron.svg';
import Container from '../Container';
import contentBlocks from '../../config/contentBlocks';

const CollapsibleContainer = styled.div`
  margin: 1em 2em 2em;

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    margin: 1em 0 2em;
  }
`;

const CollapsibleHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.elementBackground};
  border-radius: ${props => props.theme.borders.radius};
  box-shadow: ${props => !props.isOpen && props.theme.shadow};
  padding: 1em;
  cursor: pointer;
  user-select: none;
  position: relative;
  z-index: 2;

  // &:hover {
  //   background: #ddd;
  // }
`;

const CollapsedText = animated(styled.div`
  background: ${props => props.theme.elementBackground};
  box-shadow: ${props => props.theme.shadow};
  overflow: hidden;
  user-select: none;
  position: relative;
  z-index: 1;
`);

const CollapsedPadding = styled.div`
  padding: 1em 5em 1em 1em;

  > div {
    margin: 0;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    padding: 1em;
  }
`;

const CollapsibleIcon = styled(Icon)`
  width: 1.6em;
  margin-left: 1em;
  transition: transform ${props => props.theme.animations.duration}
    ${props => props.theme.animations.easing};
`;

const CollapsibleContent = ({ fields, ...props }) => {
  const { heading, collection } = fields;
  const [open, toggle] = useState(false);
  const [bind] = useMeasure();

  const spring = useSpring({
    opacity: open ? 1 : 0,
    /**
     * maxHeight is being used here temporarily until we can figure out how to
     * use useMeasure to get the height of a hidden element and animate the
     * height instead. Overall, this animation could be improved with some work.
     */
    maxHeight: open ? 2000 : 0,
    x: open ? 0 : -20,
  });

  return (
    <Container>
      <CollapsibleContainer>
        <CollapsibleHeading isOpen={open} onClick={() => toggle(!open)}>
          <H3>{heading}</H3>
          <CollapsibleIcon
            style={{
              transform: open ? 'rotate(180deg) translateX(-0.3em)' : 'none',
            }}
            inline
            icon={ArrowIcon}
          />
        </CollapsibleHeading>
        <CollapsedText
          {...bind}
          style={{
            height: open ? 'auto' : 0,
            opacity: spring.opacity,
            maxHeight: spring.maxHeight,
            transform: spring.x.interpolate(x => `translate3d(0,${x}px,0)`),
          }}
        >
          <CollapsedPadding>
            {collection &&
              collection.length &&
              collection.map((item, i) => {
                const { sys } = item;
                const itemContentType =
                  sys.contentType &&
                  sys.contentType.sys &&
                  sys.contentType.sys.id;
                if (!itemContentType) {
                  return false;
                }

                const ContentBlock = contentBlocks[itemContentType];

                return (
                  ContentBlock && (
                    <ContentBlock
                      key={sys.contentType.sys.id || i}
                      {...item}
                      fullWidth
                      isNested
                    />
                  )
                );
              })}
          </CollapsedPadding>
        </CollapsedText>
      </CollapsibleContainer>
    </Container>
  );
};

CollapsibleContent.propTypes = {
  /** The hidden content within the component */
  children: PropTypes.node,
  /** Text to use for the heading */
  heading: PropTypes.string.isRequired,
  /**
   * Whether to chain this collapsible conent with others around it and close
   * all of them except the one just opened.
   * TODO: This is not enabled yet
   */
  accordion: PropTypes.bool,
};

CollapsibleContent.defaultProps = {
  heading: '',
  accordion: false,
};

export default CollapsibleContent;
