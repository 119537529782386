import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components/macro';
import MarkdownParser from '../MarkdownParser';
import Container from '../Container';
import { H1 } from '../Heading';
import Waypoint from '../Waypoint';
import Image from '../Image';
import { Squiggle } from '../../assets/squiggle-2.svg';
import { ReactComponent as Sparkle1 } from '../../assets/sparkle-1.svg';
// import { ReactComponent as Sparkle2 } from '../../assets/sparkle-2.svg';
import { ReactComponent as Sparkle3 } from '../../assets/sparkle-3.svg';

const StyledH1 = styled(H1)`
  color: ${props => props.theme.colors.black};
  font-size: 4.6em;
  line-height: 1;
  padding: 0;
  position: relative;

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 3em;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    font-size: 2.5em;
  }

  // span {
  //   color: ${props => props.theme.colors.black};
  //   display: inline-block;
  //   background-image: url(${Squiggle});
  //   background-size: contain;
  //   background-position: center center;
  //   background-repeat: no-repeat;
  //   font-family: ${props => props.theme.fonts.buntaro};
  //   margin-bottom: 1em;
  //   opacity: ${props => (props.shouldAnimate ? 1 : 0)};
  //   transition: opacity 2s ease-in;
  // }

  p {
    transform-origin: 50% 100%;
    opacity: ${props => (props.shouldAnimate ? 1 : 0)};
    transform: ${props =>
      props.shouldAnimate ? 'translateY(0);' : 'translateY(-0.3em);'};
    transition: opacity 2s ease-in-out,
      transform 1s cubic-bezier(0.69, 0.05, 0.53, 0.99);
  }
`;

export const HeroStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  min-width: 100%;
  min-height: ${props => (props.height ? props.height : '100vh')};
  background-image: ${props =>
    props.backgroundImage
      ? `url(${props.backgroundImage.fields.file.url})`
      : null};
  background-size: ${props =>
    props.backgroundSize ? props.backgroundSize : 'cover'};
  background-repeat: no-repeat;
  background-position: center center;
  color: ${props => props.theme.colors.black};

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    min-height: 50vh;
    background-size: 200%;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    flex-direction: column;
    margin-top: 2em;
  }
`;

const StyledContent = styled.div`
  width: 70%;

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    width: 90%;
  }
`;

const StyledImg = styled.img`
  max-height: 60vh;
  margin-bottom: -20%;

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    max-height: 100%;
  }
`;

const ForegroundContainer = styled.div`
  position: relative;
  width: 30%;

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    width: 60%;
    margin-top: 3em;
  }
`;

const bloopDeBloop = keyframes`
  0% {
    opacity: 0;
    // transform: scale(0.5);
    transform: translateY(0.5em);
  }
  60% {
    // transform: scale(1.1);
  }
  50% {
    // transform: scale(0.95);
    transform: translateY(-0.2em);
  }
  100% {
    opacity: 1;
    // transform: scale(1);
    transform: translateY(0);
  }
`;

const RewardContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 45%;
  height: 45%;

  @media (max-height: 1000px) and (min-width: 1400px) {
    width: 25%;
    height: 25%;
    left: 15%;
  }
`;

const RewardImage = styled(Image)`
  position: absolute;
  top: 5%;
  left: -25%;
  // width: 100%;
  max-width: 100%;
  max-height: 100%;
  opacity: 0;
  // opacity: ${props => (props.activeIndex ? 1 : 0)};
  // transform: scale(${props => (props.activeIndex ? 1 : 0.5)});
  // transition: all 0.3s ease-in-out;
  ${props =>
    props.activeIndex &&
    css`
      animation: ${bloopDeBloop} 0.6s ease-in-out 4ms both;
    `}

  // @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
  //   top: 0;
  //   left: -10%;
  // }

  @media screen and (min-width: ${props => props.theme.breakpoints.xlarge}) {
    top: 0;
    left: -10%;
  }
`;

const SparklesContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  height: 30%;

  @media (max-height: 1000px) and (min-width: 1400px) {
    width: 20%;
    height: 20%;
    left: 15%;
  }
`;

const popIn = keyframes`
  0% {
    transform: scale(0.8)
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  80% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
    transform: scale(1)
  }
`;

const SparkleImage1 = styled(Sparkle1)`
  position: absolute;
  top: -40%;
  left: -20%;
  max-width: 100%;
  max-height: 100%;

  @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
    top: -45%;
    left: -15%;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.xlarge}) {
    top: -60%;
    left: 5%;
  }

  .animate-me {
    opacity: 0;
    animation: ${popIn} 1s linear 4ms infinite;
  }
  // EMBARASS: Couldn't figure out a dynamic, programmatic iteration in styled components
  .animate-me:nth-of-type(1) {
    animation-delay: 0.1s;
  }
  .animate-me:nth-of-type(2) {
    animation-delay: 0.2s;
  }
  .animate-me:nth-of-type(3) {
    animation-delay: 0.3s;
  }
  .animate-me:nth-of-type(4) {
    animation-delay: 0.4s;
  }
  .animate-me:nth-of-type(5) {
    animation-delay: 0.5s;
  }
  .animate-me:nth-of-type(6) {
    animation-delay: 0.1s;
  }
  .animate-me:nth-of-type(7) {
    animation-delay: 0.2s;
  }
`;

const SparkleImage3 = styled(Sparkle3)`
  position: absolute;
  top: -40%;
  left: -20%;

  @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
    top: -45%;
    left: -15%;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.xlarge}) {
    top: -60%;
    left: -10%;
  }

  .animate-me {
    opacity: 0;
    animation: ${popIn} 1s linear 4ms infinite;
  }
  // EMBARASS: Couldn't figure out a dynamic, programmatic iteration in styled components
  .animate-me:nth-of-type(1) {
    animation-delay: 0.1s;
  }
  .animate-me:nth-of-type(2) {
    animation-delay: 0.2s;
  }
  .animate-me:nth-of-type(3) {
    animation-delay: 0.3s;
  }
  .animate-me:nth-of-type(4) {
    animation-delay: 0.4s;
  }
  .animate-me:nth-of-type(5) {
    animation-delay: 0.5s;
  }
  .animate-me:nth-of-type(6) {
    animation-delay: 0.1s;
  }
  .animate-me:nth-of-type(7) {
    animation-delay: 0.2s;
  }
`;

const Hero = ({ fields }) => {
  const [shouldAnimate, setAnimate] = useState(false);
  const {
    title,
    body,
    backgroundImage,
    foregroundImage,
    backgroundSize,
    rewards,
    height,
  } = fields;

  /**
   * Auto rotate through rewards image aray
   */
  const [activeIndex, setActiveIndex] = useState(0);
  const autoPlayRef = useRef();

  useEffect(() => {
    autoPlayRef.current = incrementArray;
  });

  useEffect(() => {
    const play = () => {
      autoPlayRef.current();
    };

    const interval = setInterval(play, 1500);
    return () => clearInterval(interval);
  }, []);

  const incrementArray = () => {
    /** If we're at the end of the array, reset to 0 */
    if (activeIndex === rewards.length - 1) {
      return setActiveIndex(0);
    }
    /** Increment the index by 1 */
    setActiveIndex(s => s + 1);
  };

  // const numOfRewards = rewards ? rewards.length : 0;

  // const displaySparkles = () => {
  //   console.log('SPARKLES!!!!');
  //   if (activeIndex % 2 == 0) {
  //     return <SparkleImage1 />;
  //     // } else if (activeIndex + 1 / 1 >= 2) {
  //     //   return <SparkleImage2 />
  //   } else return <SparkleImage3 />;
  // };

  const [sparkle, setSparkle] = useState(<SparkleImage1 />);
  useEffect(() => {
    if (activeIndex % 2 === 0) {
      setSparkle(<SparkleImage1 />);
      // } else if (activeIndex + 1 / 1 >= 2) {
      //   return <SparkleImage2 />
    } else setSparkle(<SparkleImage3 />);
  }, [activeIndex]);

  return (
    <HeroStyled
      backgroundImage={backgroundImage || null}
      height={height}
      size={backgroundSize}
    >
      <Waypoint
        topOffset="0"
        bottomOffset="0"
        onEnter={() => setAnimate(true)}
        onLeave={() => setAnimate(false)}
      >
        <StyledContainer size="wide" shouldAnimate={shouldAnimate}>
          <StyledContent>
            {title && (
              <StyledH1 shouldAnimate={shouldAnimate}>
                <MarkdownParser>{title}</MarkdownParser>
              </StyledH1>
            )}
            {body && <MarkdownParser>{body}</MarkdownParser>}
          </StyledContent>
          <ForegroundContainer>
            {foregroundImage && (
              <StyledImg src={foregroundImage.fields.file.url} alt="" />
            )}
            {rewards && (
              <>
                <RewardContainer>
                  {rewards.map((reward, i) => (
                    <RewardImage
                      key={reward + i}
                      src={reward.fields.file.url}
                      activeIndex={activeIndex === i}
                    />
                  ))}
                </RewardContainer>
                <SparklesContainer>
                  {/* <SparkleImage1 /> */}
                  {sparkle}
                  {/* {displaySparkles()} */}
                  {/* <SparkleImage1 activeIndex={activeIndex + 1 <= numOfRewards / 3} />
                  <SparkleImage2 activeIndex={(activeIndex + 1 >= numOfRewards / 3) && (activeIndex + 1 <= numOfRewards / 3 * 2)} />
                  <SparkleImage3 activeIndex={(activeIndex + 1 >= numOfRewards / 6) &&(activeIndex + 1 <= numOfRewards / 3 * 3)} /> */}
                </SparklesContainer>
              </>
            )}
          </ForegroundContainer>
        </StyledContainer>
      </Waypoint>
    </HeroStyled>
  );
};

Hero.propTypes = {
  fields: PropTypes.shape({
    /** Body is the only required child of fields */
    // body: PropTypes.string.isRequired,
    backgroundImage: PropTypes.shape({
      fields: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }),
    foregroundImage: PropTypes.shape({
      fields: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
};

export default Hero;
