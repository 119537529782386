import styled from 'styled-components/macro';

/**
 * NOTE: Do not use this for adding padding around components like sections or
 * for extending extra styles in block components. It'll cause too many issues
 * and necessary overrides in nested components that also include Containers.
 * Instead use the outer component of each block or a separate element.
 *
 * Add a custom className for a nested selector below
 */
const Container = styled.div.attrs(_ => ({ className: 'container' }))`
  // width: 80%;
  /** Allow for slim, wide, and normal sized containers */
  width: ${props =>
    props.fullWidth
      ? '100%'
      : props.size === 'wide'
      ? '75%'
      : props.size === 'slim'
      ? '60rem'
      : '72rem'};
  max-width: 100%;
  margin: 0 auto;

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    width: 90%;
  }

  /**
   * We only want the top-level Container to constrain the width, in case there
   * are nested Containers. An easier way to write this selector would be
   * \`& & { width: 100% }\`, but that hasn't worked. Instead, use the custom
   * className we defined above to find nested Containers
   */
  .container & {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
`;

export default Container;
